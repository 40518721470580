import React from 'react'
import "../footer/Footer.css"

const Footer = () => {
  return (
    <div className='container'>
        <div className='copyright'>
            <p>Copyright @ Rupesh Gautam</p>
        </div>
    </div>
  )
}

export default Footer